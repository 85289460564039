import NextRouter, { useRouter, withRouter } from 'next/router';
import { compile } from 'path-to-regexp';

import { LANGUAGE_CODE } from '@/constants/language';
import { checkIsNavigationAllowed, suspendNavigation } from '@/utils/navigationGuard';

export type INextRouterQuery = Record<string, string | number>;

export const navigate = (href: string, { replace, force }: { replace?: boolean; force?: boolean } = {}) => {
  const doNavigation = () =>
    replace
      ? NextRouter.replace(href, undefined, { locale: false })
      : NextRouter.push(href, undefined, { locale: false });
  if (checkIsNavigationAllowed() || force) {
    return doNavigation();
  }
  suspendNavigation(doNavigation);
};

export const getCurrentLanguage = (): LANGUAGE_CODE =>
  (NextRouter.locale?.toLowerCase() as LANGUAGE_CODE) ?? LANGUAGE_CODE.EN;

export const getNavigationHref = (url: string, params?: INextRouterQuery) =>
  `/${getCurrentLanguage()}${params ? compile(url)(params) : url}`;

const pushRoute = (url: string, params?: INextRouterQuery, options?: { force: boolean }) =>
  navigate(getNavigationHref(url, params), options);

const replaceRoute = (url: string, params?: INextRouterQuery, options?: { force: boolean }) =>
  navigate(getNavigationHref(url, params), { replace: true, ...options });

const Router = {
  navigate,
  pushRoute,
  replaceRoute,
  back: NextRouter.back,
};

export { NextRouter, useRouter, withRouter };

export default Router;
