import { v4 as uuid } from 'uuid';

import { ALERT_TYPE } from '@/constants/alert';

import { ACTION_TYPE, dispatch, Toast } from './useToaster';

type ToastOptions = { id: string; visible: boolean };
type Message = string;
type ToastHandler = (message: Message, options?: ToastOptions) => string;
const createToast = (message: Message, type: ALERT_TYPE = ALERT_TYPE.SUCCESS, opts?: ToastOptions): Toast => ({
  type,
  message,
  visible: true,
  ...opts,
  id: opts?.id || uuid(),
});

const createHandler =
  (type?: ALERT_TYPE): ToastHandler =>
  (message, options) => {
    const toast = createToast(message, type, options);
    dispatch({ type: ACTION_TYPE.UPSERT_TOAST, toast });
    return toast.id;
  };

const toast = (message: Message, opts?: ToastOptions) => createHandler(ALERT_TYPE.SUCCESS)(message, opts);

toast.error = createHandler(ALERT_TYPE.FAIL);
toast.success = createHandler(ALERT_TYPE.SUCCESS);

toast.dismiss = (toastId?: string) => {
  dispatch({
    type: ACTION_TYPE.DISMISS_TOAST,
    toastId,
  });
};

export { toast };
