export const productRoot = '/product';
export const riderRoot = '/rider';
export const masterPolicySchemeRoot = '/master-policy-scheme';
export const libraryRoot = '/library';
export const productTemplateRoot = '/product-template';
export const productManagementRoots = [productRoot, riderRoot, libraryRoot, productTemplateRoot];
export const personalApplicationRoot = '/per-application';
export const commercialApplicationRoot = '/com-application';
export const certificateApplicationRoot = '/certificate-application';
export const applicationManagementRoots = [
  personalApplicationRoot,
  commercialApplicationRoot,
  certificateApplicationRoot,
];
export const personalPolicyRoot = '/per-policy';
export const commercialPolicyRoot = '/com-policy';
export const certificatePolicyRoot = '/certificate-policy';
export const memberRoot = '/member';
export const campaignRoot = '/campaign';
export const eventSubscriptionRoot = '/event-subscription';
export const entityRoot = '/entity';
export const masterPolicyRoot = '/master-policy';

export const systemModuleRoots = [eventSubscriptionRoot];

const cancellation = {
  refundRule: 'refund-rule',
};

const productRule = {
  attributes: 'attributes',
  questionGroups: 'question-groups',
  riskClassification: 'risk-classification',
  pricing: 'pricing',
  tax: 'tax',
  underwriting: 'underwriting',
  cancellation: 'cancellation',
  reinstatement: 'reinstatement',
};

const productSubPages = {
  basicSettings: 'basic-settings',
  plans: 'plans',
  rule: 'rule',
  document: 'document',
  policyWording: 'policy-wording',
  versionHistory: 'version-history',
  internalRemarks: 'internal-remarks',
  activityLog: 'activity-log',
};

const riderSubPages = {
  workbench: 'workbench',
  riderSettings: 'rider-settings',
  policyWording: 'policy-wording',
  changeRequest: 'change-request',
  rule: 'rule',
};

const riderRule = {
  attributes: 'attributes',
  riskClassification: 'risk-classification',
  pricing: 'pricing',
  tax: 'tax',
  underwriting: 'underwriting',
  cancellation: 'cancellation',
  reinstatement: 'reinstatement',
};

const productTemplate = {
  workbench: 'workbench',
  basicSettings: 'basic-settings',
} as const;

const masterPolicyScheme = {
  workbench: 'workbench',
  basicSettings: 'basic-settings',
  attributes: 'attributes',
  activityLogs: 'activity-logs',
} as const;

const taxLibrary = {
  tax: 'tax',
  basicSetting: 'basic-setting',
  versionList: 'version-list',
  activityLog: 'activity-log',
} as const;

const members = {
  workbench: 'member-list',
  memberInfo: 'member-info',
  information: 'account-info',
  policies: 'policies',
  activityLogs: 'activity-logs',
  changeRequests: 'change-requests',
  remarks: 'remarks',
};

const entity = {
  workbench: 'workbench',
  information: 'info',
  masterPolicies: 'master-policies',
  activityLogs: 'activity-logs',
} as const;

const campaign = {
  workbench: 'workbench',
  basicSettings: 'basic-settings',
  campaignDetails: 'campaign-details',
  criteria: 'criteria',
  changeRequests: 'change-requests',
  activityLogs: 'activity-logs',
  remarks: 'remarks',
};

const application = {
  workbench: 'workbench',
  application: 'application',
  remarks: 'remarks',
  activityLogs: 'activity-logs',
  // Legacy but required for FI & Cigna
  apiLog: 'api-log',
};

const policy = {
  workbench: 'workbench',
  policy: 'policy',
  policyInfo: 'policy-info',
  endorsements: 'endorsements',
  changeRequests: 'change-requests',
  internalRemarks: 'internal-remarks',
  activityLogs: 'activity-logs',
  analytics: 'analytics',
  certificateApplications: 'certificate-applications',
  certificatePolicies: 'certificate-policies',
};

const eventSubscription = {
  workbench: 'workbench',
  basicSettings: 'basic-settings',
};

export const pathnames = {
  Index: '/',
  ExpiredToken: '/expired-token',
  Batches: '/batches',
  eventSubscription: {
    Workbench: `${eventSubscriptionRoot}/${eventSubscription.workbench}`,
    BasicSettings: `${eventSubscriptionRoot}/[eventSubscriptionId]/${eventSubscription.basicSettings}`,
  },
  Member: {
    Workbench: `${memberRoot}/${members.workbench}`,
    memberInformation: {
      Information: `${memberRoot}/${members.memberInfo}/[memberId]/${members.information}`,
      Policies: `${memberRoot}/${members.memberInfo}/[memberId]/${members.policies}`,
      ChangeRequests: `${memberRoot}/${members.memberInfo}/[memberId]/${members.changeRequests}`,
      ActivityLogs: `${memberRoot}/${members.memberInfo}/[memberId]/${members.activityLogs}`,
      Remarks: `${memberRoot}/${members.memberInfo}/[memberId]/${members.remarks}`,
    },
  },
  Entity: {
    Workbench: `${entityRoot}/${entity.workbench}`,
    Information: `${entityRoot}/[entityId]/${entity.information}`,
    MasterPolicies: `${entityRoot}/[entityId]/${entity.masterPolicies}`,
    ActivityLogs: `${entityRoot}/[entityId]/${entity.activityLogs}`,
  },
  product: {
    Workbench: `${productRoot}/workbench`,
    BasicSettings: `${productRoot}/[productId]/${productSubPages.basicSettings}`,
    Plans: `${productRoot}/[productId]/${productSubPages.plans}`,
    PlanSetting: `${productRoot}/[productId]/${productSubPages.plans}/[planId]`,
    rule: {
      Attributes: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.attributes}`,
      AttributeSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.attributes}/[attrId]`,
      QuestionGroups: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.questionGroups}`,
      QuestionWorkspace: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.questionGroups}/[groupType]/[groupName]`,
      RiskClassification: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.riskClassification}`,
      RiskClassificationSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.riskClassification}/[ruleId]`,
      Tax: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.tax}`,
      TaxSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.tax}/[ruleId]`,
      Pricing: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.pricing}`,
      PricingSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.pricing}/[ruleId]`,
      Underwriting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.underwriting}`,
      UnderwritingSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.underwriting}/[ruleId]`,
      Cancellation: {
        Root: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.cancellation}`,
        RefundRule: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.cancellation}/${cancellation.refundRule}`,
      },
      Reinstatement: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.reinstatement}`,
      ReinstatementSetting: `${productRoot}/[productId]/${productSubPages.rule}/${productRule.reinstatement}/[ruleId]`,
    },
    PolicyWording: {
      Root: `${productRoot}/[productId]/${productSubPages.policyWording}`,
      NewAttachment: `${productRoot}/[productId]/${productSubPages.policyWording}/new`,
      EditAttachment: `${productRoot}/[productId]/${productSubPages.policyWording}/[attachmentId]`,
    },
    VersionHistory: `${productRoot}/[productId]/${productSubPages.versionHistory}`,
    InternalRemarks: `${productRoot}/[productId]/${productSubPages.internalRemarks}`,
    ActivityLog: `${productRoot}/[productId]/${productSubPages.activityLog}`,
  },
  rider: {
    Workbench: `${riderRoot}/workbench`,
    RiderSettings: `${riderRoot}/[riderId]/${riderSubPages.riderSettings}`,
    PolicyWording: `${riderRoot}/[riderId]/${riderSubPages.policyWording}`,
    rule: {
      Attributes: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.attributes}`,
      AttributeSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.attributes}/[attrId]`,
      RiskClassification: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.riskClassification}`,
      RiskClassificationSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.riskClassification}/[ruleId]`,
      Tax: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.tax}`,
      TaxSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.tax}/[ruleId]`,
      Pricing: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.pricing}`,
      PricingSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.pricing}/[ruleId]`,
      Underwriting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.underwriting}`,
      UnderwritingSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.underwriting}/[ruleId]`,
      Cancellation: {
        Root: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.cancellation}`,
        RefundRule: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.cancellation}/${cancellation.refundRule}`,
      },
      Reinstatement: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.reinstatement}`,
      ReinstatementSetting: `${riderRoot}/[riderId]/${riderSubPages.rule}/${riderRule.reinstatement}/[ruleId]`,
    },
  },
  library: {
    Workbench: `${libraryRoot}`,
    Tax: {
      Index: `${libraryRoot}/${taxLibrary.tax}`,
      BasicSetting: `${libraryRoot}/${taxLibrary.tax}/[taxId]/${taxLibrary.basicSetting}`,
      VersionList: {
        Index: `${libraryRoot}/${taxLibrary.tax}/[taxId]/${taxLibrary.versionList}`,
        NewVersion: `${libraryRoot}/${taxLibrary.tax}/[taxId]/${taxLibrary.versionList}/create-new`,
        BasicSetting: `${libraryRoot}/${taxLibrary.tax}/[taxId]/${taxLibrary.versionList}/[versionId]`,
      },
      ActivityLog: `${libraryRoot}/${taxLibrary.tax}/[taxId]/${taxLibrary.activityLog}`,
    },
  },
  productTemplate: {
    Workbench: `${productTemplateRoot}/${productTemplate.workbench}`,
    BasicSettings: `${productTemplateRoot}/[productTemplateId]/${productTemplate.basicSettings}`,
  },
  masterPolicyScheme: {
    Workbench: `${masterPolicySchemeRoot}/${masterPolicyScheme.workbench}`,
    BasicSettings: `${masterPolicySchemeRoot}/[masterPolicySchemeId]/${masterPolicyScheme.basicSettings}`,
    Attributes: `${masterPolicySchemeRoot}/[masterPolicySchemeId]/${masterPolicyScheme.attributes}`,
    AttributeSetting: `${masterPolicySchemeRoot}/[masterPolicySchemeId]/${masterPolicyScheme.attributes}/[attrId]`,
    ActivityLogs: `${masterPolicySchemeRoot}/[masterPolicySchemeId]/${masterPolicyScheme.activityLogs}`,
  },
  personalApplication: {
    Workbench: `${personalApplicationRoot}/${application.workbench}`,
    Application: `${personalApplicationRoot}/[applicationId]/${application.application}`,
    Remarks: `${personalApplicationRoot}/[applicationId]/${application.remarks}`,
    ActivityLogs: `${personalApplicationRoot}/[applicationId]/${application.activityLogs}`,
    // Legacy but required for FI & Cigna
    ApiLog: `${personalApplicationRoot}/[applicationId]/${application.apiLog}`,
  },
  commercialApplication: {
    Workbench: `${commercialApplicationRoot}/${application.workbench}`,
    Application: `${commercialApplicationRoot}/[applicationId]/${application.application}`,
    Remarks: `${commercialApplicationRoot}/[applicationId]/${application.remarks}`,
    ActivityLogs: `${commercialApplicationRoot}/[applicationId]/${application.activityLogs}`,
    // Legacy but required for FI & Cigna
    ApiLog: `${commercialApplicationRoot}/[applicationId]/${application.apiLog}`,
  },
  certificateApplication: {
    Workbench: `${certificateApplicationRoot}/${application.workbench}`,
    Application: `${certificateApplicationRoot}/[applicationId]/${application.application}`,
    Remarks: `${certificateApplicationRoot}/[applicationId]/${application.remarks}`,
    ActivityLogs: `${certificateApplicationRoot}/[applicationId]/${application.activityLogs}`,
  },
  personalPolicy: {
    Analytics: `${personalPolicyRoot}/${policy.analytics}`,
    Workbench: `${personalPolicyRoot}/${policy.workbench}`,
    PolicyInfoRoot: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/${policy.policyInfo}`,
    PolicyInfo: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.policyInfo}`,
    EndorsementsRoot: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/${policy.endorsements}`,
    Endorsements: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.endorsements}`,
    ChangeRequestsRoot: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/${policy.changeRequests}`,
    ChangeRequests: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.changeRequests}`,
    InternalRemarksRoot: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/${policy.internalRemarks}`,
    InternalRemarks: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.internalRemarks}`,
    ActivityLogsRoot: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/${policy.activityLogs}`,
    ActivityLogs: `${personalPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.activityLogs}`,
  },
  commercialPolicy: {
    Analytics: `${commercialPolicyRoot}/${policy.analytics}`,
    Workbench: `${commercialPolicyRoot}/${policy.workbench}`,
    PolicyInfoRoot: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/${policy.policyInfo}`,
    PolicyInfo: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.policyInfo}`,
    EndorsementsRoot: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/${policy.endorsements}`,
    Endorsements: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.endorsements}`,
    ChangeRequestsRoot: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/${policy.changeRequests}`,
    ChangeRequests: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.changeRequests}`,
    InternalRemarksRoot: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/${policy.internalRemarks}`,
    InternalRemarks: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.internalRemarks}`,
    ActivityLogsRoot: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/${policy.activityLogs}`,
    ActivityLogs: `${commercialPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.activityLogs}`,
  },
  masterPolicy: {
    Workbench: `${masterPolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${masterPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.policyInfo}`,
    ActivityLogs: `${masterPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.activityLogs}`,
    CertificateApplications: `${masterPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.certificateApplications}`,
    CertificatePolicies: `${masterPolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.certificatePolicies}`,
  },
  certificatePolicy: {
    Analytics: `${certificatePolicyRoot}/${policy.analytics}`,
    Workbench: `${certificatePolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${certificatePolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.policyInfo}`,
    Endorsements: `${certificatePolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.endorsements}`,
    ChangeRequests: `${certificatePolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.changeRequests}`,
    InternalRemarks: `${certificatePolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.internalRemarks}`,
    ActivityLogs: `${certificatePolicyRoot}/${policy.policy}/[policyNumber]/[policyId]/${policy.activityLogs}`,
  },
  campaign: {
    Workbench: `${campaignRoot}/${campaign.workbench}`,
    BasicSettings: `${campaignRoot}/[campaignId]/${campaign.basicSettings}`,
    CampaignDetails: `${campaignRoot}/[campaignId]/${campaign.campaignDetails}`,
    Criteria: `${campaignRoot}/[campaignId]/${campaign.criteria}`,
    ChangeRequests: `${campaignRoot}/[campaignId]/${campaign.changeRequests}`,
    Remarks: `${campaignRoot}/[campaignId]/${campaign.remarks}`,
    ActivityLogs: `${campaignRoot}/[campaignId]/${campaign.activityLogs}`,
  },
  Error: '/_error',
  NotFound: '/404',
};

export const ignoreAuthPathnames = [pathnames.Index, pathnames.ExpiredToken, pathnames.Error, pathnames.NotFound];

/**
 * URL strings should following the pattern of [`path-to-regexp`](https://github.com/pillarjs/path-to-regexp)
 */

const Urls = {
  Index: '/',
  Forbidden: '/403',
  NotFound: '/404',
  ExpiredToken: '/expired-token',
  Batches: '/batches',
  EventSubscription: {
    Workbench: `${eventSubscriptionRoot}/${eventSubscription.workbench}`,
    BasicSettings: `${eventSubscriptionRoot}/:eventSubscriptionId/${eventSubscription.basicSettings}`,
  },
  Member: {
    Workbench: `${memberRoot}/${members.workbench}`,
    memberInformation: {
      Information: `${memberRoot}/${members.memberInfo}/:memberId/${members.information}`,
      Policies: `${memberRoot}/${members.memberInfo}/:memberId/${members.policies}`,
      ChangeRequests: `${memberRoot}/${members.memberInfo}/:memberId/${members.changeRequests}`,
      ActivityLogs: `${memberRoot}/${members.memberInfo}/:memberId/${members.activityLogs}`,
      Remarks: `${memberRoot}/${members.memberInfo}/:memberId/${members.remarks}`,
    },
  },
  Entity: {
    Workbench: `${entityRoot}/${entity.workbench}`,
    Information: `${entityRoot}/:entityId/${entity.information}`,
    MasterPolicies: `${entityRoot}/:entityId/${entity.masterPolicies}`,
    ActivityLogs: `${entityRoot}/:entityId/${entity.activityLogs}`,
  },
  product: {
    Workbench: `${productRoot}/workbench`,
    BasicSettings: `${productRoot}/:productId/${productSubPages.basicSettings}`,
    Plans: `${productRoot}/:productId/${productSubPages.plans}/:planId?`,
    rule: {
      Attributes: `${productRoot}/:productId/${productSubPages.rule}/${productRule.attributes}`,
      AttributeSetting: `${productRoot}/:productId/${productSubPages.rule}/${productRule.attributes}/:attrId?`,
      QuestionGroups: `${productRoot}/:productId/${productSubPages.rule}/${productRule.questionGroups}/:groupType?/:groupName?`,
      RiskClassification: `${productRoot}/:productId/${productSubPages.rule}/${productRule.riskClassification}/:ruleId?`,
      Pricing: `${productRoot}/:productId/${productSubPages.rule}/${productRule.pricing}/:ruleId?`,
      Tax: `${productRoot}/:productId/${productSubPages.rule}/${productRule.tax}/:ruleId?`,
      Underwriting: `${productRoot}/:productId/${productSubPages.rule}/${productRule.underwriting}/:ruleId?`,
      Cancellation: {
        Root: `${productRoot}/:productId/${productSubPages.rule}/${productRule.cancellation}`,
        RefundRule: `${productRoot}/:productId/${productSubPages.rule}/${productRule.cancellation}/${cancellation.refundRule}`,
      },
      Reinstatement: `${productRoot}/:productId/${productSubPages.rule}/${productRule.reinstatement}/:ruleId?`,
    },
    PolicyWording: {
      Root: `${productRoot}/:productId/${productSubPages.policyWording}`,
      NewAttachment: `${productRoot}/:productId/${productSubPages.policyWording}/new`,
      EditAttachment: `${productRoot}/:productId/${productSubPages.policyWording}/:attachmentId`,
    },
    VersionHistory: `${productRoot}/:productId/${productSubPages.versionHistory}`,
    InternalRemarks: `${productRoot}/:productId/${productSubPages.internalRemarks}`,
    ActivityLog: `${productRoot}/:productId/${productSubPages.activityLog}`,
  },
  rider: {
    Workbench: `${riderRoot}/workbench`,
    RiderSettings: `${riderRoot}/:riderId/${riderSubPages.riderSettings}`,
    PolicyWording: `${riderRoot}/:riderId/${riderSubPages.policyWording}`,
    rule: {
      Attributes: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.attributes}`,
      AttributeSetting: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.attributes}/:attrId?`,
      RiskClassification: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.riskClassification}/:ruleId?`,
      Pricing: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.pricing}/:ruleId?`,
      Tax: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.tax}/:ruleId?`,
      Underwriting: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.underwriting}/:ruleId?`,
      Cancellation: {
        Root: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.cancellation}`,
        RefundRule: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.cancellation}/${cancellation.refundRule}`,
      },
      Reinstatement: `${riderRoot}/:riderId/${riderSubPages.rule}/${riderRule.reinstatement}/:ruleId?`,
    },
  },
  library: {
    Workbench: `${libraryRoot}`,
    Tax: {
      Index: `${libraryRoot}/${taxLibrary.tax}`,
      BasicSetting: `${libraryRoot}/${taxLibrary.tax}/:taxId/${taxLibrary.basicSetting}`,
      VersionList: {
        Index: `${libraryRoot}/${taxLibrary.tax}/:taxId/${taxLibrary.versionList}`,
        NewVersion: `${libraryRoot}/${taxLibrary.tax}/:taxId/${taxLibrary.versionList}/create-new`,
        BasicSetting: `${libraryRoot}/${taxLibrary.tax}/:taxId/${taxLibrary.versionList}/:versionId`,
      },
      ActivityLog: `${libraryRoot}/${taxLibrary.tax}/:taxId/${taxLibrary.activityLog}`,
    },
  },
  productTemplate: {
    Workbench: `${productTemplateRoot}/${productTemplate.workbench}`,
    BasicSettings: `${productTemplateRoot}/:productTemplateId/${productTemplate.basicSettings}`,
  },
  masterPolicyScheme: {
    Workbench: `${masterPolicySchemeRoot}/${masterPolicyScheme.workbench}`,
    BasicSettings: `${masterPolicySchemeRoot}/:masterPolicySchemeId/${masterPolicyScheme.basicSettings}`,
    Attributes: `${masterPolicySchemeRoot}/:masterPolicySchemeId/${masterPolicyScheme.attributes}`,
    AttributeSetting: `${masterPolicySchemeRoot}/:masterPolicySchemeId/${masterPolicyScheme.attributes}/:attrId?`,
    ActivityLogs: `${masterPolicySchemeRoot}/:masterPolicySchemeId/${masterPolicyScheme.activityLogs}`,
  },
  personalApplication: {
    Workbench: `${personalApplicationRoot}/${application.workbench}`,
    Application: `${personalApplicationRoot}/:applicationId/${application.application}`,
    Remarks: `${personalApplicationRoot}/:applicationId/${application.remarks}`,
    ActivityLogs: `${personalApplicationRoot}/:applicationId/${application.activityLogs}`,
    // Legacy but required for FI & Cigna
    ApiLog: `${personalApplicationRoot}/:applicationId/${application.apiLog}`,
  },
  commercialApplication: {
    Workbench: `${commercialApplicationRoot}/${application.workbench}`,
    Application: `${commercialApplicationRoot}/:applicationId/${application.application}`,
    Remarks: `${commercialApplicationRoot}/:applicationId/${application.remarks}`,
    ActivityLogs: `${commercialApplicationRoot}/:applicationId/${application.activityLogs}`,
    // Legacy but required for FI & Cigna
    ApiLog: `${commercialApplicationRoot}/:applicationId/${application.apiLog}`,
  },
  certificateApplication: {
    Workbench: `${certificateApplicationRoot}/${application.workbench}`,
    Application: `${certificateApplicationRoot}/:applicationId/${application.application}`,
    Remarks: `${certificateApplicationRoot}/:applicationId/${application.remarks}`,
    ActivityLogs: `${certificateApplicationRoot}/:applicationId/${application.activityLogs}`,
  },
  personalPolicy: {
    Analytics: `${personalPolicyRoot}/${policy.analytics}`,
    Workbench: `${personalPolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${personalPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.policyInfo}`,
    Endorsements: `${personalPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.endorsements}`,
    ChangeRequests: `${personalPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.changeRequests}`,
    InternalRemarks: `${personalPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.internalRemarks}`,
    ActivityLogs: `${personalPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.activityLogs}`,
  },
  commercialPolicy: {
    Analytics: `${commercialPolicyRoot}/${policy.analytics}`,
    Workbench: `${commercialPolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${commercialPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.policyInfo}`,
    Endorsements: `${commercialPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.endorsements}`,
    ChangeRequests: `${commercialPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.changeRequests}`,
    InternalRemarks: `${commercialPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.internalRemarks}`,
    ActivityLogs: `${commercialPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.activityLogs}`,
  },
  masterPolicy: {
    Workbench: `${masterPolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${masterPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.policyInfo}`,
    ActivityLogs: `${masterPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.activityLogs}`,
    CertificateApplications: `${masterPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.certificateApplications}`,
    CertificatePolicies: `${masterPolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.certificatePolicies}`,
  },
  certificatePolicy: {
    Analytics: `${certificatePolicyRoot}/${policy.analytics}`,
    Workbench: `${certificatePolicyRoot}/${policy.workbench}`,
    PolicyInfo: `${certificatePolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.policyInfo}`,
    Endorsements: `${certificatePolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.endorsements}`,
    ChangeRequests: `${certificatePolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.changeRequests}`,
    InternalRemarks: `${certificatePolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.internalRemarks}`,
    ActivityLogs: `${certificatePolicyRoot}/${policy.policy}/:policyNumber/:policyId?/${policy.activityLogs}`,
  },
  campaign: {
    Workbench: `${campaignRoot}/${campaign.workbench}`,
    BasicSettings: `${campaignRoot}/:campaignId/${campaign.basicSettings}`,
    CampaignDetails: `${campaignRoot}/:campaignId/${campaign.campaignDetails}`,
    Criteria: `${campaignRoot}/:campaignId/${campaign.criteria}`,
    ChangeRequests: `${campaignRoot}/:campaignId/${campaign.changeRequests}`,
    Remarks: `${campaignRoot}/:campaignId/${campaign.remarks}`,
    ActivityLogs: `${campaignRoot}/:campaignId/${campaign.activityLogs}`,
  },
};

export default Urls;
