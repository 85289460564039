export const MAX_REMARK_ATTACHMENT_NUMBER = 6;
export const REMARK_LIMIT_FILE_SIZE = 25 * 1024 * 1024; // 25mb

export enum REMARK_ATTACHMENT {
  ID = 'id',
  NAME = 'name',
  STATE = 'state',
}

export enum REMARK_ATTACHMENT_STATE {
  DEFAULT = 'default',
  PENDING = 'pending',
  UPLOADING = 'uploading',
  UPLOAD_FAILED = 'failed',
  UPLOAD_SUCCESS = 'success',
}

export const REMARK_COLLAPSED_REPLY_NUMBER = 2;
