import { useEffect, useState } from 'react';

import { ALERT_TYPE } from '@/constants/alert';

export type Toast = {
  message: string;
  id: string;
  type: ALERT_TYPE;
  visible: boolean;
};

export enum ACTION_TYPE {
  ADD_TOAST,
  REMOVE_TOAST,
  UPSERT_TOAST,
  UPDATE_TOAST,
  DISMISS_TOAST,
}

type Action =
  | {
      type: ACTION_TYPE.ADD_TOAST;
      toast: Toast;
    }
  | {
      type: ACTION_TYPE.UPSERT_TOAST;
      toast: Toast;
    }
  | {
      type: ACTION_TYPE.UPDATE_TOAST;
      toast: Partial<Toast>;
    }
  | {
      type: ACTION_TYPE.DISMISS_TOAST;
      toastId?: string;
    }
  | {
      type: ACTION_TYPE.REMOVE_TOAST;
      toastId?: string;
    };

interface State {
  toasts: Toast[];
}
const toastTimeouts = new Map<Toast['id'], ReturnType<typeof setTimeout>>();

export const TOAST_EXPIRE_DISMISS_DELAY = 1000;

const addToRemoveQueue = (toastId: string) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch({
      type: ACTION_TYPE.REMOVE_TOAST,
      toastId,
    });
  }, TOAST_EXPIRE_DISMISS_DELAY);

  toastTimeouts.set(toastId, timeout);
};

const clearFromRemoveQueue = (toastId: string) => {
  const timeout = toastTimeouts.get(toastId);
  if (timeout) {
    clearTimeout(timeout);
  }
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPE.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    case ACTION_TYPE.UPSERT_TOAST:
      // eslint-disable-next-line no-case-declarations
      const { toast } = action;
      return state.toasts.find(t => t.id === toast.id)
        ? reducer(state, { type: ACTION_TYPE.UPDATE_TOAST, toast })
        : reducer(state, { type: ACTION_TYPE.ADD_TOAST, toast });

    case ACTION_TYPE.UPDATE_TOAST:
      //  ! Side effects !
      if (action.toast.id) {
        clearFromRemoveQueue(action.toast.id);
      }

      return {
        ...state,
        toasts: state.toasts.map(t => (t.id === action.toast.id ? { ...t, ...action.toast } : t)),
      };
    case ACTION_TYPE.DISMISS_TOAST:
      // eslint-disable-next-line no-case-declarations
      const { toastId } = action;

      // ! Side effects ! - This could be execrated into a dismissToast() action, but I'll keep it here for simplicity
      if (toastId) {
        addToRemoveQueue(toastId);
      } else {
        state.toasts.forEach(t => {
          addToRemoveQueue(t.id);
        });
      }

      return {
        ...state,
        toasts: state.toasts.map(t =>
          t.id === toastId || toastId === undefined
            ? {
                ...t,
                visible: false,
              }
            : t
        ),
      };
    case ACTION_TYPE.REMOVE_TOAST:
    default:
      if (action.toastId === undefined) {
        return {
          ...state,
          toasts: [],
        };
      }
      return {
        ...state,
        toasts: state.toasts.filter(t => t.id !== action.toastId),
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { toasts: [] };

export const dispatch = (action: Action) => {
  memoryState = reducer(memoryState, action);
  listeners.forEach(listener => {
    listener(memoryState);
  });
};

export const useToaster = (): State => {
  const [state, setState] = useState<State>(memoryState);

  useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    ...state,
  };
};
