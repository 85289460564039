import { IdType } from '@ixt/utilities';
import { useMutation } from '@tanstack/react-query';

import { IXT_API_ENDPOINT_ATTACHMENT } from '../constants/endpoint';
import { components } from '../types/version1';
import { ixtDownloadFn, ixtMutationFn } from '../utils/fetchers';

export const useAttachmentDownloadMutation = () =>
  useMutation(
    ({ attachmentId, fileName, openNewTab }: { attachmentId: IdType; fileName: string; openNewTab?: boolean }) =>
      ixtDownloadFn({
        path: `${IXT_API_ENDPOINT_ATTACHMENT}/${attachmentId}`,
        fileName,
        openNewTab,
      })
  );

export const asyncUploadAttachment = (file: File) => {
  const body = new FormData();
  body.append('file', file);

  return ixtMutationFn<FormData, components['schemas']['PartialAttachmentModel']>({
    method: 'POST',
    path: IXT_API_ENDPOINT_ATTACHMENT,
    request: body,
  });
};

export const useAttachmentUploadMutation = () => useMutation(asyncUploadAttachment);

export const asyncDeleteAttachment = (attachmentId: IdType) =>
  ixtMutationFn({
    method: 'DELETE',
    path: `${IXT_API_ENDPOINT_ATTACHMENT}/${attachmentId}`,
  });

export const useAttachmentDeleteMutation = () => useMutation(asyncDeleteAttachment);
