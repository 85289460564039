import { FC, ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import Colors from '@/constants/colors';
import Typography from '@/constants/typography';
import delayUnmounting from '@/decorators/delayUnmounting';

import SpinnerLoading from '../loading/SpinnerLoading';
import ModalCloseButton from './materials/ModalCloseButton';
import ModalFooter from './materials/ModalFooter';
import ModalRoot from './materials/ModalRoot';
import ModalTitle from './materials/ModalTitle';

export { ModalFooter, ModalTitle };

const defaultDelay = 200;

export enum CONTENT_TYPE {
  DEFAULT = 'default',
  GRAY = 'gray',
  NAKED = 'naked',
}

const Description = styled.div.attrs({ className: Typography.BODY_M })`
  color: ${Colors.BLACK_065};
  padding: 2rem 2.5rem 2.5rem;
  overflow-wrap: break-word;
`;

const ScrollableWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
  padding: 2rem 2.5rem 2.5rem;
`;

export const NakedContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const GrayContent = styled.div`
  flex: 1;
  padding: 1.5rem 2.5rem;
  background: ${Colors.GRAY_100};
  border-top: 0.0625rem solid ${Colors.BLACK_009};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27.25rem;
`;

// TODO: id should be required
interface Props {
  id?: string;
  show: boolean;
  wide?: boolean;
  loading?: boolean;
  /**
   * enable the scaling animation of the Modal component (enabled by default)
   */
  animate?: boolean;
  delay?: number;
  title?: ReactNode;
  description?: ReactNode;
  contentType?: CONTENT_TYPE;
  className?: string;
  renderContent?: () => ReactNode;
  renderButtons?: () => ReactNode;
  onClose?: () => void;
  onBackdropClick?: () => void;
}

const DefaultModal: FC<Props> = ({
  id,
  wide,
  show,
  loading,
  animate = true,
  delay = defaultDelay,
  title,
  description,
  onClose,
  onBackdropClick,
  contentType = CONTENT_TYPE.DEFAULT,
  renderContent,
  renderButtons,
  className,
}) => {
  const renderContentWithType = useCallback(
    (render: NonNullable<Props['renderContent']>) => {
      switch (contentType) {
        case CONTENT_TYPE.GRAY:
          return <GrayContent>{render()}</GrayContent>;
        case CONTENT_TYPE.NAKED:
          return <NakedContent>{render()}</NakedContent>;
        case CONTENT_TYPE.DEFAULT:
        default:
          return <Content>{render()}</Content>;
      }
    },
    [contentType]
  );

  return (
    <ModalRoot
      id={id}
      className={className}
      wide={wide}
      show={show}
      delay={delay}
      animate={animate}
      onBackdropClick={onBackdropClick}
    >
      {onClose && <ModalCloseButton id="default-modal-close-btn" onClick={onClose} />}
      {title && <ModalTitle>{title}</ModalTitle>}
      {loading ? (
        <Loading>
          <SpinnerLoading />
        </Loading>
      ) : (
        <ScrollableWrapper>
          {description && <Description>{description}</Description>}

          {renderContent && renderContentWithType(renderContent)}
        </ScrollableWrapper>
      )}
      {renderButtons && <ModalFooter>{renderButtons()}</ModalFooter>}
    </ModalRoot>
  );
};

export default delayUnmounting<Props>(DefaultModal, defaultDelay);
