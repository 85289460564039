import { PLATFORM_ODG } from '@/platforms/constants';

import { pathnames } from './urls';

/*
{
  id: identifier,
  pathnames: all possible pathnames, put the default one at index 0,
}
 */
export interface IExternalPageConfig {
  id: string;
  ignorePlatforms: string[];
}

export interface IPageConfig {
  id: string;
  pathnames: string[];
  ignorePlatforms: string[];
}

export const BASIC_PAGES: Record<string, IPageConfig> = {
  INDEX: {
    id: 'page-index',
    pathnames: [pathnames.Index],
    ignorePlatforms: [],
  },

  EXPIRED_TOKEN: {
    id: 'page-expired-token',
    pathnames: [pathnames.ExpiredToken],
    ignorePlatforms: [],
  },
};

export const PRODUCT_MANAGEMENT_MODULE_PAGES: Record<string, IPageConfig> = {
  PRODUCT_WORKBENCH: {
    id: 'page-product-workbench',
    pathnames: [pathnames.product.Workbench],
    ignorePlatforms: [],
  },

  PRODUCT_BASIC_SETTINGS: {
    id: 'page-product-basic-settings',
    pathnames: [pathnames.product.BasicSettings],
    ignorePlatforms: [],
  },

  PRODUCT_PLANS: {
    id: 'page-product-plans',
    pathnames: [pathnames.product.Plans, pathnames.product.PlanSetting],
    ignorePlatforms: [],
  },

  PRODUCT_PLANS_NEW: {
    id: 'page-product-plans-new',
    pathnames: ['/product/[productId]/plans_new/[planId]'],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_ATTRIBUTES: {
    id: 'page-product-rule-attributes',
    pathnames: [pathnames.product.rule.Attributes, pathnames.product.rule.AttributeSetting],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_QUESTION_GROUPS: {
    id: 'page-product-rule-question-groups',
    pathnames: [pathnames.product.rule.QuestionGroups, pathnames.product.rule.QuestionWorkspace],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_RISK_CLASSIFICATION: {
    id: 'page-product-rule-risk-classification',
    pathnames: [pathnames.product.rule.RiskClassification, pathnames.product.rule.RiskClassificationSetting],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_PRICING: {
    id: 'page-product-rule-pricing',
    pathnames: [pathnames.product.rule.Pricing, pathnames.product.rule.PricingSetting],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_TAX: {
    id: 'page-product-rule-tax',
    pathnames: [pathnames.product.rule.Tax, pathnames.product.rule.TaxSetting],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_UNDERWRITING: {
    id: 'page-product-rule-underwriting',
    pathnames: [pathnames.product.rule.Underwriting, pathnames.product.rule.UnderwritingSetting],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_CANCELLATION: {
    id: 'page-product-rule-cancellation',
    pathnames: [pathnames.product.rule.Cancellation.Root, pathnames.product.rule.Cancellation.RefundRule],
    ignorePlatforms: [],
  },

  PRODUCT_RULE_REINSTATEMENT: {
    id: 'page-product-rule-reinstatement',
    pathnames: [pathnames.product.rule.Reinstatement, pathnames.product.rule.ReinstatementSetting],
    ignorePlatforms: [],
  },

  PRODUCT_POLICY_WORDING: {
    id: 'page-product-policy-wording',
    pathnames: [
      pathnames.product.PolicyWording.Root,
      pathnames.product.PolicyWording.NewAttachment,
      pathnames.product.PolicyWording.EditAttachment,
    ],
    ignorePlatforms: [],
  },

  PRODUCT_VERSION_HISTORY: {
    id: 'page-product-version-history',
    pathnames: [pathnames.product.VersionHistory],
    ignorePlatforms: [],
  },

  PRODUCT_INTERNAL_REMARKS: {
    id: 'page-product-internal-remarks',
    pathnames: [pathnames.product.InternalRemarks],
    ignorePlatforms: [],
  },

  PRODUCT_ACTIVITY_LOG: {
    id: 'page-product-activity-log',
    pathnames: [pathnames.product.ActivityLog],
    ignorePlatforms: [],
  },

  RIDER_WORKBENCH: {
    id: 'page-rider-workbench',
    pathnames: [pathnames.rider.Workbench],
    ignorePlatforms: [],
  },

  RIDER_RIDER_SETTINGS: {
    id: 'page-rider-rider-settings',
    pathnames: [pathnames.rider.RiderSettings],
    ignorePlatforms: [],
  },

  RIDER_RIDER_RULE_ATTRIBUTES: {
    id: 'page-rider-rule-attributes',
    pathnames: [pathnames.rider.rule.Attributes, pathnames.rider.rule.AttributeSetting],
    ignorePlatforms: [],
  },

  RIDER_RULE_RISK_CLASSIFICATION: {
    id: 'page-rider-rule-risk-classification',
    pathnames: [pathnames.rider.rule.RiskClassification, pathnames.rider.rule.RiskClassificationSetting],
    ignorePlatforms: [],
  },

  RIDER_RULE_PRICING: {
    id: 'page-rider-rule-pricing',
    pathnames: [pathnames.rider.rule.Pricing, pathnames.rider.rule.PricingSetting],
    ignorePlatforms: [],
  },

  RIDER_RULE_TAX: {
    id: 'page-rider-rule-tax',
    pathnames: [pathnames.rider.rule.Tax, pathnames.rider.rule.TaxSetting],
    ignorePlatforms: [],
  },

  RIDER_RULE_UNDERWRITING: {
    id: 'page-rider-rule-underwriting',
    pathnames: [pathnames.rider.rule.Underwriting, pathnames.rider.rule.UnderwritingSetting],
    ignorePlatforms: [],
  },

  RIDER_RULE_CANCELLATION: {
    id: 'page-rider-rule-cancellation',
    pathnames: [pathnames.rider.rule.Cancellation.Root, pathnames.rider.rule.Cancellation.RefundRule],
    ignorePlatforms: [],
  },

  RIDER_RULE_REINSTATEMENT: {
    id: 'page-rider-rule-reinstatement',
    pathnames: [pathnames.rider.rule.Reinstatement, pathnames.rider.rule.ReinstatementSetting],
    ignorePlatforms: [],
  },

  RIDER_POLICY_WORDING: {
    id: 'page-rider-policy-wording',
    pathnames: [pathnames.rider.PolicyWording],
    ignorePlatforms: [],
  },

  LIBRARY: {
    id: 'page-library',
    pathnames: [
      pathnames.library.Workbench,
      pathnames.library.Tax.Index,
      pathnames.library.Tax.BasicSetting,
      pathnames.library.Tax.VersionList.Index,
      pathnames.library.Tax.VersionList.NewVersion,
      pathnames.library.Tax.VersionList.BasicSetting,
    ],
    ignorePlatforms: [],
  },

  PRODUCT_TEMPLATE: {
    id: 'page-product-template',
    pathnames: [pathnames.productTemplate.Workbench, pathnames.productTemplate.BasicSettings],
    ignorePlatforms: [],
  },
};

export const SYSTEM_ADMINISTRATION_MODULE_EXTERNAL_PAGES: Record<string, IExternalPageConfig> = {
  TEAMS: {
    id: 'page-teams',
    ignorePlatforms: [PLATFORM_ODG], // TODO: hidden in ixt-system 0.1.0
  },

  USERS: {
    id: 'page-users',
    ignorePlatforms: [],
  },

  ROLES: {
    id: 'page-roles',
    ignorePlatforms: [],
  },

  AUDIT_LOGS: {
    id: 'page-audit-logs',
    ignorePlatforms: [PLATFORM_ODG], // TODO: hidden in ixt-system 0.1.0
  },

  AUTHENTICATION_SETTING: {
    id: 'page-authentication-setting',
    ignorePlatforms: [],
  },
};

export const EVENT_SUBSCRIPTION_MODULE_PAGES: Record<string, IPageConfig> = {
  EVENT_SUBSCRIPTION: {
    id: 'page-event-subscription',
    pathnames: [pathnames.eventSubscription.Workbench, pathnames.eventSubscription.BasicSettings],
    ignorePlatforms: [],
  },
};

export const INSURANCE_OPERATION_MODULE_PAGES: Record<string, IPageConfig> = {
  PERSONAL_APPLICATION_WORKBENCH: {
    id: 'page-personal-application-workbench',
    pathnames: [pathnames.personalApplication.Workbench],
    ignorePlatforms: [],
  },

  PERSONAL_APPLICATION_APPLICATION: {
    id: 'page-personal-application-application',
    pathnames: [pathnames.personalApplication.Application],
    ignorePlatforms: [],
  },

  PERSONAL_APPLICATION_REMARKS: {
    id: 'page-personal-application-remarks',
    pathnames: [pathnames.personalApplication.Remarks],
    ignorePlatforms: [],
  },

  PERSONAL_APPLICATION_ACTIVITY_LOGS: {
    id: 'page-personal-application-activity-logs',
    pathnames: [pathnames.personalApplication.ActivityLogs],
    ignorePlatforms: [],
  },

  // Legacy but required for FI & Cigna
  PERSONAL_APPLICATION_API_LOG: {
    id: 'page-personal-application-api-log',
    pathnames: [pathnames.personalApplication.ApiLog],
    ignorePlatforms: [PLATFORM_ODG],
  },

  COMMERCIAL_APPLICATION_WORKBENCH: {
    id: 'page-commercial-application-workbench',
    pathnames: [pathnames.commercialApplication.Workbench],
    ignorePlatforms: [],
  },

  COMMERCIAL_APPLICATION_APPLICATION: {
    id: 'page-commercial-application-application',
    pathnames: [pathnames.commercialApplication.Application],
    ignorePlatforms: [],
  },

  COMMERCIAL_APPLICATION_REMARKS: {
    id: 'page-commercial-application-remarks',
    pathnames: [pathnames.commercialApplication.Remarks],
    ignorePlatforms: [],
  },

  COMMERCIAL_APPLICATION_ACTIVITY_LOGS: {
    id: 'page-commercial-application-activity-logs',
    pathnames: [pathnames.commercialApplication.ActivityLogs],
    ignorePlatforms: [],
  },

  // Legacy but required for FI & Cigna
  COMMERCIAL_APPLICATION_API_LOG: {
    id: 'page-commercial-application-api-log',
    pathnames: [pathnames.commercialApplication.ApiLog],
    ignorePlatforms: [PLATFORM_ODG],
  },

  CERTIFICATE_APPLICATION_WORKBENCH: {
    id: 'page-certificate-application-workbench',
    pathnames: [pathnames.certificateApplication.Workbench],
    ignorePlatforms: [],
  },

  CERTIFICATE_APPLICATION_APPLICATION: {
    id: 'page-certificate-application-application',
    pathnames: [pathnames.certificateApplication.Application],
    ignorePlatforms: [],
  },

  CERTIFICATE_APPLICATION_REMARKS: {
    id: 'page-certificate-application-remarks',
    pathnames: [pathnames.certificateApplication.Remarks],
    ignorePlatforms: [],
  },

  CERTIFICATE_APPLICATION_ACTIVITY_LOGS: {
    id: 'page-certificate-application-activity-logs',
    pathnames: [pathnames.certificateApplication.ActivityLogs],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_ANALYTICS: {
    id: 'page-personal-policy-analytics',
    pathnames: [pathnames.personalPolicy.Analytics],
    ignorePlatforms: [PLATFORM_ODG],
  },

  PERSONAL_POLICY_WORKBENCH: {
    id: 'page-personal-policy-workbench',
    pathnames: [pathnames.personalPolicy.Workbench],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_INFO_ROOT: {
    id: 'page-personal-policy-info-root',
    pathnames: [pathnames.personalPolicy.PolicyInfoRoot],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_INFO: {
    id: 'page-personal-policy-info',
    pathnames: [pathnames.personalPolicy.PolicyInfo],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_ENDORSEMENTS_ROOT: {
    id: 'page-personal-policy-endorsement-root',
    pathnames: [pathnames.personalPolicy.EndorsementsRoot],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_ENDORSEMENTS: {
    id: 'page-personal-policy-endorsement',
    pathnames: [pathnames.personalPolicy.Endorsements],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_CHANGE_REQUESTS_ROOT: {
    id: 'page-personal-policy-change-request-root',
    pathnames: [pathnames.personalPolicy.ChangeRequestsRoot],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_CHANGE_REQUESTS: {
    id: 'page-personal-policy-change-request',
    pathnames: [pathnames.personalPolicy.ChangeRequests],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_INTERNAL_REMARKS_ROOT: {
    id: 'page-personal-policy-internal-remarks',
    pathnames: [pathnames.personalPolicy.InternalRemarksRoot],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_INTERNAL_REMARKS: {
    id: 'page-personal-policy-internal-remarks',
    pathnames: [pathnames.personalPolicy.InternalRemarks],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_ACTIVITY_LOGS_ROOT: {
    id: 'page-personal-policy-activity-log-root',
    pathnames: [pathnames.personalPolicy.ActivityLogsRoot],
    ignorePlatforms: [],
  },

  PERSONAL_POLICY_ACTIVITY_LOGS: {
    id: 'page-personal-policy-activity-log',
    pathnames: [pathnames.personalPolicy.ActivityLogs],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_WORKBENCH: {
    id: 'page-commercial-policy-workbench',
    pathnames: [pathnames.commercialPolicy.Workbench],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_INFO: {
    id: 'page-commercial-policy-info',
    pathnames: [pathnames.commercialPolicy.PolicyInfo],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_ENDORSEMENTS: {
    id: 'page-commercial-policy-endorsement',
    pathnames: [pathnames.commercialPolicy.Endorsements],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_CHANGE_REQUESTS: {
    id: 'page-commercial-policy-change-request',
    pathnames: [pathnames.commercialPolicy.ChangeRequests],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_INTERNAL_REMARKS: {
    id: 'page-commercial-policy-internal-remarks',
    pathnames: [pathnames.commercialPolicy.InternalRemarks],
    ignorePlatforms: [],
  },

  COMMERCIAL_POLICY_ACTIVITY_LOGS: {
    id: 'page-commercial-policy-activity-log',
    pathnames: [pathnames.commercialPolicy.ActivityLogs],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_WORKBENCH: {
    id: 'page-certificate-policy-workbench',
    pathnames: [pathnames.certificatePolicy.Workbench],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_INFO: {
    id: 'page-certificate-policy-info',
    pathnames: [pathnames.certificatePolicy.PolicyInfo],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_ENDORSEMENTS: {
    id: 'page-certificate-policy-endorsement',
    pathnames: [pathnames.certificatePolicy.Endorsements],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_CHANGE_REQUESTS: {
    id: 'page-certificate-policy-change-request',
    pathnames: [pathnames.certificatePolicy.ChangeRequests],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_INTERNAL_REMARKS: {
    id: 'page-certificate-policy-internal-remarks',
    pathnames: [pathnames.certificatePolicy.InternalRemarks],
    ignorePlatforms: [],
  },

  CERTIFICATE_POLICY_ACTIVITY_LOGS: {
    id: 'page-certificate-policy-activity-log',
    pathnames: [pathnames.certificatePolicy.ActivityLogs],
    ignorePlatforms: [],
  },
};

export const MEMBER_MODULE_PAGES: Record<string, IPageConfig> = {
  MEMBER_WORKBENCH: {
    id: 'page-member-workbench',
    pathnames: [pathnames.Member.Workbench],
    ignorePlatforms: [],
  },

  MEMBER_INFORMATION: {
    id: 'page-member-information',
    pathnames: [pathnames.Member.memberInformation.Information],
    ignorePlatforms: [],
  },

  MEMBER_POLICIES: {
    id: 'page-member-policies',
    pathnames: [pathnames.Member.memberInformation.Policies],
    ignorePlatforms: [],
  },

  MEMBER_CHANGE_REQUESTS: {
    id: 'page-member-change-request',
    pathnames: [pathnames.Member.memberInformation.ChangeRequests],
    ignorePlatforms: [],
  },

  MEMBER_ACTIVITY_LOGS: {
    id: 'page-member-activity-logs',
    pathnames: [pathnames.Member.memberInformation.ActivityLogs],
    ignorePlatforms: [],
  },

  MEMBER_REMARKS: {
    id: 'page-member-remarks',
    pathnames: [pathnames.Member.memberInformation.Remarks],
    ignorePlatforms: [],
  },

  ENTITY_WORKBENCH: {
    id: 'page-entity-workbench',
    pathnames: [pathnames.Entity.Workbench],
    ignorePlatforms: [],
  },

  ENTITY_INFORMATION: {
    id: 'page-entity-information',
    pathnames: [pathnames.Entity.Information],
    ignorePlatforms: [],
  },

  ENTITY_MASTER_POLICIES: {
    id: 'page-entity-master-policies',
    pathnames: [pathnames.Entity.MasterPolicies],
    ignorePlatforms: [],
  },

  ENTITY_ACTIVITY_LOGS: {
    id: 'page-entity-activity-logs',
    pathnames: [pathnames.Entity.ActivityLogs],
    ignorePlatforms: [],
  },
};

export const CAMPAIGN_MODULE_PAGES: Record<string, IPageConfig> = {
  CAMPAIGN_WORKBENCH: {
    id: 'page-campaign-workbench',
    pathnames: [pathnames.campaign.Workbench],
    ignorePlatforms: [],
  },

  CAMPAIGN_BASIC_SETTINGS: {
    id: 'page-campaign-basic-settings',
    pathnames: [pathnames.campaign.BasicSettings],
    ignorePlatforms: [],
  },

  CAMPAIGN_DETAILS: {
    id: 'page-campaign-details',
    pathnames: [pathnames.campaign.CampaignDetails],
    ignorePlatforms: [],
  },

  CAMPAIGN_CRITERIA_PRODUCT_PLANS: {
    id: 'page-campaign-criteria-product-plans',
    pathnames: [pathnames.campaign.Criteria],
    ignorePlatforms: [],
  },

  CAMPAIGN_CHANGE_REQUESTS: {
    id: 'page-campaign-change-request',
    pathnames: [pathnames.campaign.ChangeRequests],
    ignorePlatforms: [],
  },

  CAMPAIGN_ACTIVITY_LOGS: {
    id: 'page-campaign-activity-logs',
    pathnames: [pathnames.campaign.ActivityLogs],
    ignorePlatforms: [],
  },

  CAMPAIGN_REMARKS: {
    id: 'page-campaign-remarks',
    pathnames: [pathnames.campaign.Remarks],
    ignorePlatforms: [],
  },
};

export const CMS_MODULE_EXTERNAL_PAGES: Record<string, IExternalPageConfig> = {
  LAUNCHPAD_PAGE: {
    id: 'page-launchpad',
    ignorePlatforms: [],
  },
};

/* TODO: ignore pages depend on platform */
const PAGES: Record<string, IPageConfig> = {
  ...BASIC_PAGES,
  ...PRODUCT_MANAGEMENT_MODULE_PAGES,
  ...INSURANCE_OPERATION_MODULE_PAGES,
  ...MEMBER_MODULE_PAGES,
  ...CAMPAIGN_MODULE_PAGES,
  ...EVENT_SUBSCRIPTION_MODULE_PAGES,
};

export default PAGES;
