import { FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { ALERT_TYPE } from '@/constants/alert';
import { COOKIE_ACCESS_TOKEN } from '@/constants/auth';
import { AuthContext } from '@/decorators/withPermission';
import { redirectToAuthAppSignIn, useAuth, useAuthWhenPageIsVisible, useReAuth } from '@/effects/useAuth';
import useLogoutTimer from '@/hooks/useLogoutTimer';
import { Toaster } from '@/patterns/alert';
import { AlertBanner } from '@/patterns/alert/AlertBanner';
import { setIsPermissionDeniedFalse, setIsSessionTimeoutFalse } from '@/redux/AuthProvider/actions';
import { isPermissionDeniedSelector, isSessionTimeoutSelector } from '@/redux/AuthProvider/selectors';
import { closeAlert, popAlert, pushAlert } from '@/redux/MiscProvider/actions';
import { alertListSelector } from '@/redux/MiscProvider/selectors';
import { useTranslation } from '@/services/I18nNext';
import { NextRouter } from '@/utils/nextRouter';

import SessionTimeoutAlertModal from './SessionTimeoutAlertModal';

interface Props {
  namespaces: string[];
  children?: ReactNode;
}

const SharedLayout: FC<Props> = ({ namespaces, children }) => {
  const { isLoggedIn, authRequired } = useContext(AuthContext);

  const { t } = useTranslation(namespaces);
  const dispatch = useDispatch();
  const handleAlertBannerClose = useCallback(payload => dispatch(closeAlert(payload)), [dispatch]);
  const handleAlertBannerUnmount = useCallback(payload => dispatch(popAlert(payload)), [dispatch]);

  const { signOut, isLoggingOut, setIsLoggingOut, setIsLoggedIn } = useAuth();
  const reAuth = useReAuth();

  const isSessionTimeout = useSelector(isSessionTimeoutSelector);
  const isPermissionDenied = useSelector(isPermissionDeniedSelector);

  useAuthWhenPageIsVisible();

  useEffect(() => {
    const handleRouteChange = () => {
      if (authRequired) {
        reAuth();
      }
      window.scrollTo({ top: 0 });
    };

    NextRouter.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      NextRouter.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [reAuth, authRequired]);

  const alerts = useSelector(alertListSelector);

  const [isSessionTimeoutAlertOpen, setIsSessionTimeoutAlertOpen] = useState(false);

  const handleCloseSessionTimeoutAlert = useCallback(() => {
    setIsSessionTimeoutAlertOpen(false);
    dispatch(setIsSessionTimeoutFalse());
    setIsLoggedIn(false);
    if (isLoggingOut) signOut();
    else redirectToAuthAppSignIn();
  }, [dispatch, isLoggingOut, setIsLoggedIn, signOut]);

  const openSessionTimeoutAlert = useCallback(() => {
    setIsSessionTimeoutAlertOpen(true);
  }, []);

  useLogoutTimer(() => {
    setIsLoggingOut(true);
    openSessionTimeoutAlert();
    Cookies.remove(COOKIE_ACCESS_TOKEN);
  });

  useEffect(() => {
    if (isLoggedIn && isSessionTimeout) openSessionTimeoutAlert();
  }, [isLoggedIn, isSessionTimeout, openSessionTimeoutAlert]);

  useEffect(() => {
    if (isPermissionDenied) {
      dispatch(
        pushAlert({
          type: ALERT_TYPE.FAIL,
          text: 'Permission denied',
        })
      );
      dispatch(setIsPermissionDeniedFalse());
    }
  }, [dispatch, isPermissionDenied]);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {authRequired ? (isLoggedIn ? children : null) : children}
      <Toaster transform={t} />
      {alerts.map(alert => (
        <AlertBanner
          key={alert.id}
          id={alert.id}
          text={t(alert.text, alert.textValues)}
          type={alert.type}
          timeout={alert.timeout}
          delay={alert.delay}
          show={alert.show}
          onClose={handleAlertBannerClose}
          onUnmount={handleAlertBannerUnmount}
        />
      ))}
      <SessionTimeoutAlertModal t={t} show={isSessionTimeoutAlertOpen} onClose={handleCloseSessionTimeoutAlert} />
    </>
  );
};

export default SharedLayout;
