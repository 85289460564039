import { createAction } from '@reduxjs/toolkit';
import { AjaxError } from 'rxjs/ajax';

import { IAlert, IPushableAlert } from '@/interfaces/alert';

import {
  IApiCallingCode,
  IApiCountry,
  IApiCurrency,
  IApiLocale,
  IApiMarket,
  IApiPlatform,
  IApiTimezone,
} from './interfaces';

export const getCallingCodeList = createAction('app/misc/GET_CALLING_CODE_LIST');
export const getCallingCodeListError = createAction<Error | AjaxError>('app/misc/GET_CALLING_CODE_LIST_ERROR');
export const receivedCallingCodeList = createAction<IApiCallingCode[]>('app/misc/RECEIVED_CALLING_CODE_LIST');

export const getCountryList = createAction('app/misc/GET_COUNTRY_LIST');
export const getCountryListError = createAction<Error | AjaxError>('app/misc/GET_COUNTRY_LIST_ERROR');
export const receivedCountryList = createAction<IApiCountry[]>('app/misc/RECEIVED_COUNTRY_LIST');

export const getCurrencyList = createAction('app/misc/GET_CURRENCY_LIST');
export const getCurrencyListError = createAction<Error | AjaxError>('app/misc/GET_CURRENCY_ERROR');
export const receivedCurrencyList = createAction<IApiCurrency[]>('app/misc/RECEIVED_CURRENCY_LIST');

export const getMarketList = createAction('app/misc/GET_MARKET_LIST');
export const getMarketListError = createAction<Error | AjaxError>('app/misc/GET_MARKET_LIST_ERROR');
export const receivedMarketList = createAction<IApiMarket[]>('app/misc/RECEIVED_MARKET_LIST');

/**
 * @deprecated use toast instead
 */
export const pushAlert = createAction<IPushableAlert>('app/misc/PUSH_ALERT');
export const closeAlert = createAction<IAlert['id']>('app/misc/CLOSE_ALERT');
export const popAlert = createAction<IAlert['id']>('app/misc/POP_ALERT');

export const changedRoute = createAction<string>('app/misc/CHANGED_ROUTE');

export const getTimezoneList = createAction('app/misc/GET_TIMEZONE_LIST');
export const getTimezoneListError = createAction<Error | AjaxError>('app/misc/GET_TIMEZONE_ERROR');
export const receivedTimezoneList = createAction<IApiTimezone[]>('app/misc/RECEIVED_TIMEZONE_LIST');

export const getPlatformList = createAction('app/misc/GET_PLATFORM_LIST');
export const getPlatformListError = createAction<Error | AjaxError>('app/misc/GET_PLATFORM_LIST_ERROR');
export const receivedPlatformList = createAction<IApiPlatform[]>('app/misc/RECEIVED_PLATFORM_LIST');

export const getLocaleList = createAction('app/misc/GET_LOCALE_LIST');
export const getLocaleListError = createAction<Error | AjaxError>('app/misc/GET_LOCALE_LIST_ERROR');
export const receivedLocaleList = createAction<IApiLocale[]>('app/misc/RECEIVED_LOCALE_LIST');
