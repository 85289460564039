import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';

import {
  BASIC_PAGES,
  CAMPAIGN_MODULE_PAGES,
  INSURANCE_OPERATION_MODULE_PAGES,
  IPageConfig,
  MEMBER_MODULE_PAGES,
  PRODUCT_MANAGEMENT_MODULE_PAGES,
} from '@/constants/pages';
import { changedRoute } from '@/redux/MiscProvider/actions';
import { useRouter } from '@/utils/nextRouter';

const GeneralModuleLoader = dynamic<{ children: ReactNode }>(
  () => import('./general').then(module => module.GeneralModuleLoader),
  {
    ssr: false,
  }
);

const ProductModuleLoader = dynamic<{ children: ReactNode }>(
  () => import('./product-management').then(module => module.ProductModuleLoader),
  {
    ssr: false,
  }
);

const InsuranceOperationModuleLoader = dynamic<{ children: ReactNode }>(
  () => import('./insurance-operation').then(module => module.InsuranceOperationModuleLoader),
  {
    ssr: false,
  }
);

const CampaignModuleLoader = dynamic<{ children: ReactNode }>(
  () => import('./campaign').then(module => module.CampaignModuleLoader),
  {
    ssr: false,
  }
);

const MemberModuleLoader = dynamic<{ children: ReactNode }>(
  () => import('./member').then(module => module.MemberModuleLoader),
  {
    ssr: false,
  }
);

const pathnameMatcher = (modulePages: Record<string, IPageConfig>) => (pathname: string) =>
  Object.values(modulePages).some(({ pathnames }) => pathnames.includes(pathname));

const ModuleLoader: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changedRoute(pathname));
  }, [dispatch, pathname]);

  if (pathnameMatcher(PRODUCT_MANAGEMENT_MODULE_PAGES)(pathname)) {
    return <ProductModuleLoader>{children}</ProductModuleLoader>;
  }

  if (pathnameMatcher(INSURANCE_OPERATION_MODULE_PAGES)(pathname)) {
    return <InsuranceOperationModuleLoader>{children}</InsuranceOperationModuleLoader>;
  }

  if (pathnameMatcher(CAMPAIGN_MODULE_PAGES)(pathname)) {
    return <CampaignModuleLoader>{children}</CampaignModuleLoader>;
  }

  if (pathnameMatcher(MEMBER_MODULE_PAGES)(pathname)) {
    return <MemberModuleLoader>{children}</MemberModuleLoader>;
  }

  if (pathnameMatcher(BASIC_PAGES)(pathname)) {
    return <GeneralModuleLoader>{children}</GeneralModuleLoader>;
  }

  return <>{children}</>;
};

export default ModuleLoader;
