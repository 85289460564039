export const IXT_API_ENDPOINT_ACTIVITY_LOG = '/activity-logs';
export const IXT_API_ENDPOINT_APPLICATION = '/applications';
export const IXT_API_ENDPOINT_ATTACHMENT = '/attachments';
export const IXT_API_ENDPOINT_ATTRIBUTE_TEMPLATE = '/attribute-templates';
export const IXT_API_ENDPOINT_ATTRIBUTES = '/attributes';
export const IXT_API_ENDPOINT_ATTRIBUTE = '/attribute';
export const IXT_API_ENDPOINT_CONSTRAINT_TEMPLATE = '/constraint-templates';
export const IXT_API_ENDPOINT_POLICY = '/policies';
export const IXT_API_ENDPOINT_POLICY_ENDORSEMENT = '/policy-endorsements';
export const IXT_API_ENDPOINT_ENDORSEMENT_REVIEW_REQUEST = '/endorsement-review-requests';
export const IXT_API_ENDPOINT_REFERENCE_TABLE = '/reference-tables';

export const IXT_API_ENDPOINT_FORM_APPLICATION = '/form-applications';

export const IXT_API_ENDPOINT_FORM = '/forms';

export const IXT_API_ENDPOINT_RULE = 'rules';

export const IXT_API_ENDPOINT_CAMPAIGN = '/campaigns';

export const IXT_API_ENDPOINT_CAMPAIGNS_GENERATE_REPORT = '/campaigns/generate-report';

export const IXT_API_ENDPOINT_INTEREST = '/interests';

export const IXT_API_ENDPOINT_PLAN = '/plans';

export const IXT_API_ENDPOINT_MASTER_POLICY_SCHEME = '/master-policy-schemes';

export const IXT_API_ENDPOINT_PRODUCT_TYPE = '/product-types';

export const IXT_API_ENDPOINT_ENTITY = '/entity-members';

export const IXT_API_ENDPOINT_CONSTRAINTS = '/constraints';

export const IXT_API_ENDPOINT_MASTER_POLICY = '/master-policies';

export const IXT_API_ENDPOINT_MEMBERS = '/members';
