import type { editor } from 'monaco-editor';

import Colors from './colors';

export const DEFAULT_TEXT_HOLDER = '- -';
export const DEFAULT_NUMBER_HOLDER = '-';
export const PER_REQUEST_ITEM_LIMIT = 20;
export const QUICK_ACCESS_ITEMS_LIMIT = 4;

export const NOOP: () => void = () => {};

/**
 * Reference Table has stringify ID, 'null' is a valid (legal) ID,
 * but since the current usage only focus on System Reference Table instead of Custom Reference Table,
 * using 'null' to represent the NULL_STRING_ID just like the NULL_ID.
 */
export const NULL_STRING_ID = 'null';

export const EMPTY_LIST = [];
export const EMPTY_MAP = new Map();

export const IS_BLACKLISTED_YES = 'YES';
export const IS_BLACKLISTED_NO = 'NO';

export const DEFAULT_COUNTRY_CODE = '+852';

export const DEFAULT_USER_SYSTEM_ADMIN = 'System Admin';

export enum DATA_TYPE {
  BOOL = 'boolean',
  NUM = 'numeric',
  STRING = 'string',
  ENUM = 'enum',
  REF = 'reference',
  ANY = 'any',
}

export enum TIME_FORMAT {
  TIME = 'HH:mm:ss',
  WITHOUT_SECOND = 'HH:mm',
}

export enum DATE_FORMAT {
  SLASH = 'DD/MM/YYYY',
  SPACE = 'DD MMM YYYY',
}

export enum DATE_TIME_FORMAT {
  SLASH_WITH_MINUTE = 'DD/MM/YYYY HH:mm',
  SLASH_WITH_SECOND = 'DD/MM/YYYY HH:mm:ss',
  SPACE_WITH_TIME = 'DD MMM YYYY HH:mm',
  SPACE_WITH_SECOND = 'DD MMM YYYY HH:mm:ss',
}

export const DEFAULT_TIME = '00:00:00';

/*
  bucketA: Case Insensitivity and exact match query string/enum
           ex: ABCD => abcd
  bucketB: Case Insensitivity and contains query string/enum
           ex: ABCD => abcd, AbCDEF
  bucketC: Case Insensitivity, contains all strings splitted by space without order (String Swapping)
           ex: BC AA => aaBBC
*/
export enum SEARCH_BUCKET {
  A = 'search_bucket_A',
  B = 'search_bucket_B',
  C = 'search_bucket_C',
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export enum GOV_ID_TYPE {
  PASSPORT = 'passport',
  IDENTITY_CARD = 'identity_card',
  BUSINESS_REGISTRATION = 'business_registration',
  RESIDENT_PERMIT = 'resident_permit',
}

// attachment
export const MAX_ATTACHMENT_NUMBER = 6;
export const LIMIT_FILE_SIZE = 25 * 1024 * 1024; // 25MB

// monaco editor
export const MONACO_EDITOR_OPTION: editor.IStandaloneEditorConstructionOptions = <const>{
  minimap: { enabled: false },
  scrollbar: { useShadows: false, horizontalScrollbarSize: 4, verticalScrollbarSize: 4 },
  scrollBeyondLastLine: false,
  lineNumbers: 'off',
  padding: { top: 16, bottom: 16 },
  contextmenu: false,
  wordWrap: 'on',
};
export const MONACO_EDITOR_THEME = 'monokai-bright';

// ajax
export enum AJAX_STATUS {
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
}

// input text max length
export const INPUT_TEXT_MAX_LENGTH = <const>{
  XS: 25,
  S: 100,
  M: 250,
  LENGTH_2000: 2000,
  L: 4000,
};

export const SERIAL_BAR_COLOR = [Colors.AUXILIARY_B_100, Colors.AUXILIARY_A_100, Colors.SECONDARY_500];
