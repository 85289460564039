import { v4 as uuid } from 'uuid';

import { BE_ORDER_BY_KEYWORD_DESC } from '@/constants/orderBy';
import { IXT_API_URL } from '@/services/OneDegree/constants';
import { mapNullsFirstToKeyword } from '@/services/OneDegree/utils';
import {
  InternalApiFields,
  InternalApiQueryKey,
  ResourceApiQueryKey,
  StringifyQueryKey,
} from '@/services/ReactQuery/interfaces';
import convertFieldsToQuery from '@/utils/convertFieldsToQuery';

import sanitizeCookie from '../../utils/sanitizeCookie';

const ACCESS_TOKEN_KEY = 'access-token';

const getAccessToken = (): string | undefined => {
  const accessTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(ACCESS_TOKEN_KEY))
    ?.split('=')[1];

  return accessTokenCookie;
};

const CSRF_TOKEN_HEADER = 'Csrf-Token';
const CSRF_TOKEN_KEY = 'CSRF-TOKEN';

const getOrCreateCSRFToken = (): string => {
  const csrfTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(CSRF_TOKEN_KEY))
    ?.split('=')[1];

  if (!csrfTokenCookie) {
    return uuid();
  }

  return csrfTokenCookie;
};

const addCSRFTokenToCookie = (csrfToken: string): void => {
  if (!document) {
    return;
  }

  const subdomain = IXT_API_URL.slice(Math.max(IXT_API_URL.indexOf('.'), 0));
  document.cookie = sanitizeCookie(`${CSRF_TOKEN_KEY}=${csrfToken};domain=${subdomain};path=/`);
};

export const getFetchQueryOptions = (headers?: Record<string, string>): RequestInit => {
  const csrfToken = getOrCreateCSRFToken();
  addCSRFTokenToCookie(csrfToken);
  const accessToken = getAccessToken();

  return {
    headers: {
      [CSRF_TOKEN_HEADER]: csrfToken,
      accept: 'application/json',
      WEBPAGE: window.location.href,
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
  };
};

const getMutationRequestBody = <TRequest>(request: TRequest): BodyInit | undefined => {
  if (request) {
    return request instanceof Blob || request instanceof FormData ? request : JSON.stringify(request);
  }
  return undefined;
};

export const getFetchMutationOptions = <TRequest>(
  method: string,
  request: TRequest,
  headers?: Record<string, string>
): RequestInit => {
  const csrfToken = getOrCreateCSRFToken();
  addCSRFTokenToCookie(csrfToken);
  const accessToken = getAccessToken();
  return {
    method,
    headers: {
      [CSRF_TOKEN_HEADER]: csrfToken,
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      Accept: 'application/json',
      ...(request instanceof Blob || request instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      nonce: Date.now().toString(),
      WEBPAGE: window.location.href,
      ...headers,
    },
    body: getMutationRequestBody(request),
    credentials: 'include',
    mode: 'cors',
  };
};

export const getInternalApiQueryUrl = (queryKey: InternalApiQueryKey): string => {
  const [resourceName, resourceId, fields, request] = queryKey;
  const resourceUrl = resourceId ? `/internal/${resourceName}/${resourceId}` : `/internal/${resourceName}`;
  const url = new URL(resourceUrl, IXT_API_URL);

  let requestQuery = {
    fields: convertFieldsToQuery(fields),
  };
  if (typeof request === 'object') {
    const { filter, orderBy, ascending, nullsFirst, ...restRequestAttributes } = request;
    const orderByQueryString = `${orderBy || ''}${
      ascending ? '' : ` ${BE_ORDER_BY_KEYWORD_DESC}${mapNullsFirstToKeyword(nullsFirst)}`
    }`;

    requestQuery = {
      ...requestQuery,
      ...restRequestAttributes,
      ...(filter ? { filter: JSON.stringify(filter) } : {}),
      ...(orderBy ? { order_by: orderByQueryString } : {}),
    };
  }
  url.search = new URLSearchParams(requestQuery).toString();

  return url.toString();
};

export const getQueryUrl = (queryKey: StringifyQueryKey): string => {
  const url = new URL(`/internal/${queryKey.join('/')}`, IXT_API_URL);
  return url.toString();
};
/**
 * @deprecated remove ixtResourceQueryFn, getResourceUrl actually used ixt-api
 */
export const getResourceUrl = <TQueryParams = Record<string, string | number | boolean>>(
  queryKey: ResourceApiQueryKey<TQueryParams>,
  baseUrl: string
): string => {
  const url = new URL(`/${queryKey[0].join('/')}`, baseUrl);
  if (queryKey[1]) {
    Object.entries(queryKey[1]).forEach(([key, value]) => {
      url.searchParams.set(key, String(value));
    });
  }
  return url.toString();
};

export const splitChunksByRoot = (fields: InternalApiFields): InternalApiFields[] => {
  const chunks = new Map<string, InternalApiFields>();

  const DEFAULT_ROOT = '$$root';

  fields.forEach(field => {
    const [rootField, ...remaining] = field.split('.');
    const remainingFields = remaining.join('.');

    if (remaining.length === 0) {
      if (chunks.has(DEFAULT_ROOT)) {
        chunks.set(DEFAULT_ROOT, [...chunks.get(DEFAULT_ROOT)!, rootField]);
      } else {
        chunks.set(DEFAULT_ROOT, [rootField]);
      }
      return;
    }

    if (chunks.has(rootField)) {
      chunks.set(rootField, [...chunks.get(rootField)!, remainingFields]);
    } else {
      chunks.set(rootField, [remainingFields]);
    }
  });

  return (
    Array.from(chunks.entries())
      .map(([root, chunkFields]) => chunkFields.map((f: string) => (root === DEFAULT_ROOT ? f : `${root}.${f}`)))
      // merge continuous small chunks to reduce the number of requests
      .reduce<InternalApiFields[]>((prev, chunk) => {
        if (prev.length === 0) return [chunk];
        if (prev[prev.length - 1].length + chunk.length <= 15) {
          return [...prev.slice(0, prev.length - 1), [...prev[prev.length - 1], ...chunk]];
        }
        return [...prev, chunk];
      }, [])
  );
};

const appendSearchParamsWithSameKey = (url: URL, key: string, filterValues?: string[]) => {
  if (filterValues && filterValues.length > 0) {
    for (const value of filterValues) {
      url.searchParams.append(key, value);
    }
  } else {
    url.searchParams.append(key, String(filterValues));
  }
  return url.toString();
};

export const getServiceQueryUrl = (
  apiService: string,
  paths: StringifyQueryKey,
  searchParams?: Record<string, string>,
  filterValues?: string[]
) => {
  const url = new URL(`/${paths.join('/')}`, apiService);
  url.search = new URLSearchParams(searchParams).toString();
  if (url.searchParams.has('filter_by') && !url.searchParams.has('filter_value')) {
    return appendSearchParamsWithSameKey(url, 'filter_values', filterValues);
  }
  return url.toString();
};
