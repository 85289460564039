import { FC, ReactNode, useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Portal from '@/components/Portal';
import Colors from '@/constants/colors';
import ZIndices from '@/constants/zIndices';
import useBodyScrollLock from '@/hooks/useBodyScrollLock';

const AnimationShow = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const AnimationHide = keyframes`
  from { transform: scale(1); }
  to { transform: scale(0); }
`;

const FadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const Backdrop = styled.div<{ $show: boolean; $animate: boolean; $duration: number }>`
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${ZIndices.Modal};
  background: ${Colors.BLACK_045};
  opacity: ${props => (props.$show ? 1 : 0)};
  transition: opacity ${props => props.$duration}ms linear;
`;

const Dialog = styled.div<{ $show: boolean; $wide?: boolean; $animate: boolean; $duration: number }>`
  ${props =>
    props.$animate &&
    css`
      animation: ${props.$show ? AnimationShow : AnimationHide} ${props.$duration}ms linear 1;
    `}
  background: ${Colors.WHITE_100};
  position: relative;
  border-radius: 0.5rem;
  width: ${props => (props.$wide ? 45 : 35.25)}rem;
  overflow: hidden;
  overflow-y: auto;
`;

const FadeInArea = styled.div`
  max-height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  animation: ${FadeIn} 1s ease;
  transition-delay: 1s;
`;

interface Props {
  id?: string;
  show: boolean;
  wide?: boolean;
  delay: number;
  animate?: boolean;
  className?: string;
  onBackdropClick?: () => void;
  children?: ReactNode;
}

// TODO: modal should define min-width to prevent broken layout
// TODO: id should be required
const ModalRoot: FC<Props> = ({ className, id, wide, show, delay, animate = true, onBackdropClick, children }) => {
  const backdropRef = useRef(null);

  const handleBackdropClick = useCallback(
    event => {
      if (onBackdropClick && backdropRef.current === event.target) {
        return onBackdropClick();
      }
    },
    [onBackdropClick]
  );

  useBodyScrollLock(show);

  return (
    <Portal>
      <Backdrop
        id={id}
        ref={backdropRef}
        className={className}
        $show={show}
        $duration={delay}
        $animate={animate}
        onClick={handleBackdropClick}
      >
        <Dialog $wide={wide} $show={show} $duration={delay} $animate={animate}>
          <FadeInArea>{children}</FadeInArea>
        </Dialog>
      </Backdrop>
    </Portal>
  );
};

export default ModalRoot;
