import { FC } from 'react';

import { AlertBanner } from '../AlertBanner';
import { toast } from './toast';
import { useToaster } from './useToaster';

type Props = {
  transform?: (arg: string) => string;
};
const Toaster: FC<Props> = ({ transform }) => {
  const { toasts } = useToaster();
  return (
    <>
      {toasts.map(alert => {
        return (
          <AlertBanner
            key={alert.id}
            id={alert.id}
            text={transform ? transform(alert.message) : alert.message}
            type={alert.type}
            show={alert.visible}
            onClose={() => toast.dismiss(alert.id)}
          />
        );
      })}
    </>
  );
};

export { Toaster, toast };
