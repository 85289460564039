import getEnvVar from '@/utils/getEnvVar';

const API_ENV = getEnvVar('API_ENV', 'dev');
export const IXT_API_URL = getEnvVar('IXT_API_URL', `https://core-gw.${API_ENV}.theixt.com`);
export const RESOURCE_INTERNAL_API_URL = getEnvVar(
  'RESOURCE_INTERNAL_API_URL',
  `https://resource-internal-api.${API_ENV}.theixt.com`
);
export const CORE_WEB_URL = getEnvVar('CORE_WEB_URL', `https://internal-web.${API_ENV}.theixt.com`);
export const AUTH_WEB_URL = getEnvVar('AUTH_WEB_URL', `https://auth.${API_ENV}.theixt.com`);
export const SYSTEM_WEB_URL = getEnvVar('SYSTEM_WEB_URL', `https://ixt-system.${API_ENV}.theixt.com`);
export const RESOURCE_WEB_URL = getEnvVar('RESOURCE_WEB_URL', `https://ixt-resource.${API_ENV}.theixt.com`);
export const PRM_WEB_URL = getEnvVar('PRM_WEB_URL', `https://ixt-prm.${API_ENV}.theixt.com`);
export const CLAIM_WEB_URL = getEnvVar('CLAIM_WEB_URL', `https://ixt-claim.${API_ENV}.theixt.com`);
export const UTILITY_WEB_URL = getEnvVar('UTILITY_WEB_URL', `https://ixt-utility.${API_ENV}.theixt.com`);
export const PANEL_WEB_URL = getEnvVar('PANEL_WEB_URL', `https://ixt-panel.${API_ENV}.theixt.com`);
export const CMS_WEB_URL = getEnvVar('CMS_WEB_URL', `https://cms.${API_ENV}.theixt.com`);
export const CSP_HOSTNAME = getEnvVar('CSP_HOSTNAME', '*.theixt.com');
